<template>
  <div>
    <div class="checkbox checkbox-primary d-inline" v-for="(field, key) in formFields" :key="field">
      <input
        type="checkbox"
        :name="key"
        :id="key"
        :disabled="key === `message_reply` || key === 'message_inbound'"
        v-model="formFields[key]"
        @change="$emit('update', formFields)"
      >
      <label :for="key" class="cr mb-0"><b>{{ $t(`${model}.${key}`) }}</b></label>
    </div>
    <div class="server-error-message content mt-3" v-if="error.length">
      {{ error }}
      <button @click.prevent="error = ''" class="feather icon-x button"></button>
    </div>
    <button
      type="submit"
      class="btn btn-primary mt-4"
      :disabled="!isTouchedData || isDisabled"
      @click.prevent="$emit('submit', formFields)"
    >{{ $t(`${model}.submit`) }}</button>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'PreferencesForm',
  props: {
    license: {
      type: Object,
      required: true,
    },
    formdata: {
      type: Object,
    },
    errorMessage: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
    isTouchedData: {
      type: Boolean,
    },
    model: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formFields = ref(JSON.parse(JSON.stringify(props.formdata)))
    const error = ref(props.errorMessage)
    return {
      formFields,
      error,
    }
  },
}
</script>
